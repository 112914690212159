export default defineComponent({
  setup: function setup() {
    var error = ref(undefined);
    var show = ref(false);
    return {
      error: error,
      show: show
    };
  },
  methods: {
    signOut: function signOut() {
      var _this = this;
      this.$Amplify.Auth.signOut({
        global: true
      }).then(function () {
        console.log("signout success");
        _this.$store.commit("signOut");
        _this.$store.commit("trkd/resetTRKD");
        _this.$store.commit("ciq/resetCIQ");
        _this.$store.commit("fmp/resetFMP");
        _this.$store.commit("watchlist/resetState");
        _this.$store.commit("updateCurrentUser", {
          user: null,
          from: "logout"
        });
        _this.$store.commit("updateAuthDisplayMap", {
          data: "signedOut"
        });
      })["catch"](function (e) {
        console.log("Logout signout error:", e);
        console.log("Logout signout error stringified:", JSON.stringify(e, null, 2));
      });
    }
  }
});